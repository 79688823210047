import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.scss';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";
import Login from './screens/Login';
import { has_permission, isLoggedIn } from './helper/functions/auth';
import Loading from './helper/screens/Loading';
import UserUnit from './communicator/http/User';
import AdminUsers from './screens/AdminUsers';
import AdminUsersEdit from './screens/AdminUsers/Add_Edit';

import { BASENAME } from './spectory-config';
import Dashboard from './screens/Dashboard';
import Check from './screens/Check';
import Validities from './screens/Validities';
import { changeLanguageHandler } from './helper/multilingual';
import PasswordForgot from './screens/PasswordForgot';
import PasswordReset from './screens/PasswordReset';
import Navigation from './elements/navigation';
import wrlog from './helper/functions/wrlog';
import Departments from './screens/Departments';

const App = () => {

    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);

    const userUnit = new UserUnit();

    useEffect(() => {
        changeLanguageHandler();

        isLoggedIn().then(() => {

            userUnit.getPermissions((permissions) => {

                sessionStorage.setItem('isAdmin', permissions.is_admin);

                setLoggedIn(true);
                setLoading(false);

            }, (err) => wrlog("ERR", err));

        }).catch(() => {
            setLoading(false);
        })

    }, []);

    const changeLocation = () => {

    }

    if (loading) {
        return <Loading visible={loading} />;
    }

    if (loggedIn) {

        return (
            <Router basename={BASENAME}>
                <div className="wrapper">
                    <Navigation />
                    <div className="content_wrapper">
                        <Routes>
                            <Route path='/' element={<Dashboard />} />
                            <Route path='/user/:id' element={<AdminUsersEdit />} />
                            <Route path='/check/:id' element={<Check />} />
                            <Route path='/check' element={<Check />} />
                            <Route path='/validities' element={<Validities />} />
                            <Route path='/departments' element={<Departments />} />
                        </Routes>
                    </div>
                </div>
            </Router>
        );

    } else {
        return (
            <Router basename={BASENAME}>
                <Routes>

                    <Route path='/forgot-password' element={<PasswordForgot />} />
                    <Route path='/reset-password/:key/:secret' element={<PasswordReset />} />
                    <Route path='*' element={<Login />} />
                </Routes>
            </Router>
        );
    }
}

export default App;