export const removeFromArrayByValue = (val, arr) => {
    
    const key = arr.indexOf(val);

    if(key < 0) return arr;

    arr.splice(key, 1);

    return arr;

}


export const cloneArray = (array) => {
    return JSON.parse(JSON.stringify(array));
}