import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import icon from '../../assets/icons/menu_bullets.png';
import { translate } from '../../helper/multilingual';

import styles from './style.module.scss';

const TopBar = ({ name }) => {

    return <div className={styles.container}>
        <h2>{translate('welcome')}, {name}</h2>
    </div>

}

export default TopBar;