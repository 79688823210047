import React from 'react';

export default class Popup extends React.Component {

    constructor(){

        super();

        this.state = {
            visible : false,
        }
    }

    close = () => {
        this.setState({
            visible : false
        })
    }

    open = () => {
        this.setState({
            visible : true
        })
    }

    render() {
        if(this.state.visible){

            return (
                <>
                    <div className="popup_close_button_overlay">
                        <div className={"popup_container"}>
                            {this.props.children}
                            <div className="popup_close_button" onClick={this.close}></div>
                        </div>
                    </div>
                </>
            );

            
        }else{
            return null;
        }
        
    }
}