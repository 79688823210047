import axios from 'axios';
import { getUserToken } from '../../helper/functions/auth.js';
import wrlog from '../../helper/functions/wrlog.js';

const API_HTTP_HOST = 'https://api.spectory.at/covidtest/v1';

export default class Unit {

    constructor() {
    }

    call = (endpoint, body, cbSuccess = () => { }, cbError = () => { }) => {

        wrlog('BODY TO SERVER', body)

        return axios.post(API_HTTP_HOST + endpoint, body)
            .then(function (response) {
                // response = JSON.parse(response.data);
                wrlog('RESPONSE FROM SERVER', response.data)

                if (response.data.code === undefined || response.data.code === 200) {

                    cbSuccess(response.data);

                } else {
                    cbError(response.data);
                }

                wrlog(response);

            })
            .catch(function (error) {
                wrlog(error);
                cbError();
            });

    }

    get = (endpoint, cbSuccess = () => { }, cbError = () => { }) => {

        wrlog('Endpoint TO SERVER', API_HTTP_HOST + endpoint)

        return axios.get(API_HTTP_HOST + endpoint, { headers: { token: getUserToken() } })
            .then(function (response) {
                // response = JSON.parse(response.data);
                wrlog('RESPONSE FROM SERVER', response.data)

                if (response.status === 200) {
                    cbSuccess(response.data);
                } else {
                    cbError(response.data);
                }

                wrlog(response);

            })
            .catch(function (error) {
                wrlog(error);
                cbError(error);
            });

    }

    post = (endpoint, body, cbSuccess = () => { }, cbError = () => { }) => {

        wrlog('BODY TO SERVER', body)
        wrlog('Endpoint TO SERVER', API_HTTP_HOST + endpoint)

        return axios.post(API_HTTP_HOST + endpoint, body, { headers: { token: getUserToken() } })
            .then(function (response) {
                // response = JSON.parse(response.data);
                wrlog('RESPONSE FROM SERVER', response.data)

                if (response.status === 200) {
                    cbSuccess(response.data);
                } else {
                    cbError(response.data);
                }

                wrlog(response);

            })
            .catch(function (error) {
                wrlog(error);
                cbError(error);
            });

    }

    put = (endpoint, body, cbSuccess = () => { }, cbError = () => { }) => {

        wrlog('BODY TO SERVER', body)
        wrlog('Endpoint TO SERVER', API_HTTP_HOST + endpoint)

        return axios.put(API_HTTP_HOST + endpoint, body, { headers: { token: getUserToken() } })
            .then(function (response) {
                wrlog('RESPONSE FROM SERVER', response.data)

                if (response.status === 200) {
                    cbSuccess(response.data);
                } else {
                    cbError(response.data);
                }

                wrlog(response);

            })
            .catch(function (error) {
                wrlog(error);
                cbError(error);
            });

    }

    delete = (endpoint, body, cbSuccess = () => { }, cbError = () => { }) => {

        wrlog('BODY TO SERVER', body)
        wrlog('Endpoint TO SERVER', API_HTTP_HOST + endpoint)

        return axios.delete(API_HTTP_HOST + endpoint, { data: body, headers: { token: getUserToken() } })
            .then(function (response) {
                // response = JSON.parse(response.data);
                wrlog('RESPONSE FROM SERVER', response.data)

                if (response.status === 200) {
                    cbSuccess(response.data);
                } else {
                    cbError(response.data);
                }

                wrlog(response);

            })
            .catch(function (error) {
                cbError(error);
            });

    }

    show_error = () => {

    }

}