import { Checkbox } from '@material-ui/core';
import React from 'react';



export default class CustomCheckbox extends React.Component {

    constructor() {

        super();

        this.state = {
            visible: false,
        }



    }

    render() {


        return (
            <Checkbox
                className={"root"}
                color="default"
                checkedIcon={<span className={"icon, checkedIcon"} />}
                icon={<span className={"icon"} />}
                inputProps={{ 'aria-label': 'decorative checkbox' }}
                {...this.props}
            />
        );

    }
}