import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import icon from '../../assets/icons/menu_bullets.png';
import { translate } from '../../helper/multilingual';

import styles from './style.module.scss';

const Navigation = () => {

    const location = useLocation();

    return <div className={styles.container}>

        <Link class={`${styles.button} ${location.pathname == '/' ? styles.current : undefined}`} to="/">Dashboard</Link>
        <Link class={`${styles.button} ${location.pathname == '/check' ? styles.current : undefined}`} to="/check">Manuelle Freigabe</Link>
        <Link class={`${styles.button} ${location.pathname == '/validities' ? styles.current : undefined}`} to="/validities">Gültigkeiten</Link>
        <Link class={`${styles.button} ${location.pathname == '/departments' ? styles.current : undefined}`} to="/departments">{translate('departments')}</Link>

        <div className={`${styles.logoutButton} ${styles.button}`} onClick={() => {
            localStorage.removeItem('token');
            localStorage.removeItem('isAdmin');
            window.location.reload();
        }}>Logout</div>
    </div>

}

export default Navigation;