import React, { Component, useState, useEffect } from 'react';
import DatePickerModule, { registerLocale, setDefaultLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import de from 'date-fns/locale/de';
import wrlog from '../../helper/functions/wrlog';

registerLocale('de', de);
setDefaultLocale('de');

const DatePicker = (props) => {

    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if (props.value !== undefined && props.value !== null && props.value !== '') {
            handleCalendarOpen();
        }
    }, [props.value]);

    const handleCalendarClose = () => {
        if (props.value === undefined || props.value === null || props.value === '') {
            setFocused(false);
        }
    }
    const handleCalendarOpen = () => {
        setFocused(true);
    }

    return <div className="formInputWrapper">
        <label for={props.id} className={`${focused ? 'focused' : ''} ${props.error ? 'error' : ''}`}>{props.label}</label>
        <DatePickerModule
            {...props}
            id={props.id}
            onCalendarClose={handleCalendarClose}
            onCalendarOpen={handleCalendarOpen}
            selected={props.value}
            dateFormat="dd.MM.yyyy"
            onChange={(res) => {
                wrlog("DATE", res)
                props.onChange(res)
            }}
            maximumDate={props.maximumDate}
        />
    </div>

}

export default DatePicker;