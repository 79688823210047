import { getUserToken } from '../../helper/functions/auth';
import Unit from './index';


export default class PermissionUnit extends Unit {

    constructor() {
        super();
    }

    load = (userId, cbSuccess, cbError) => {
        this.get('/permission/'+userId, cbSuccess, cbError);
    }

    set = (data, cbSuccess, cbError) => {
        this.post('/permission', data, cbSuccess, cbError);
    }

}



