import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UserUnit from '../../communicator/http/User';
import Loading from '../../helper/screens/Loading';


export default class AdminUsers extends React.Component {

    constructor() {
        super();

        this.state = {
            users: [],
            loading: true,
            partial_loading: false
        }
    }

    componentDidMount() {

        this.props.set_current_page(this.props.location.pathname);

        let userUnit = new UserUnit(this.props.com);
        let that = this;

        let cbSuccess = (res) => {
            that.setState({
                users: res,
                loading: false
            });
        }

        let cbError = (res) => {

            that.setState({
                loading: false
            });
        }

        let users_list = JSON.parse(sessionStorage.getItem('admin_users_list'));

        let cb_reload_success = (res) => {

            that.setState({
                users: res,
                partial_loading: false
            });
        }

        if (users_list === null) {

            userUnit.list(cbSuccess, cbError);

        } else {

            this.setState({
                users: users_list,
                loading: false,
                partial_loading: true
            });

            userUnit.list(cb_reload_success, cbError);
            
        }

    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row style={{marginTop: 44}}>
                        <Col md={8}><h1>Benutzer</h1></Col>

                        <Col md={4} className="align-right">
                            <Link to={"/user/new"} className="add_button">Neuer Benutzer</Link>
                        </Col>
                    </Row>
                </Container>

                <Table style={{ marginTop: 50 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell style={{ textAlign: 'right' }}>{this.state.partial_loading ? <div><Loading visible={true} relative style={{ width: 20, height: 20 }} container_style={{ width: 20, height: 20, display: 'inline-block', verticalAlign: 'middle', marginRight: 10 }} /> Synchronizing with server</div> : null}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.state.users.length > 0 ?
                                this.state.users.map((user, key) => {
                                    return <TableRow key={key}>
                                        <TableCell>
                                            {user.firstname} {user.lastname}
                                        </TableCell>
                                        <TableCell className="align-right">
                                            <Link to={"/user/" + user.id}>edit</Link>
                                        </TableCell>
                                    </TableRow>
                                }) : null
                        }
                    </TableBody>
                </Table>
                <Loading visible={this.state.loading} relative />
            </>

        )
    }
}