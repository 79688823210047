import { Checkbox, FormControlLabel, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Input } from '@material-ui/core';
import React, { useRef, useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../helper/screens/Loading';
import { validation } from '../../helper/validation';
import Notification from '../../helper/screens/Notification';
import Popup from '../../helper/screens/Popup';

import CustomCheckbox from '../../helper/screens/CustomCheckbox';
import UserUnit from '../../communicator/http/User';
import ValidityUnit from '../../communicator/http/Validity';
import { cloneArray, removeFromArrayByValue } from '../../helper/functions/array';

import saveButton from '../../assets/icons/save.png';
import deleteButton from '../../assets/icons/trash.png';
import { translate } from '../../helper/multilingual';

import NotFound from '../../assets/illustrations/not_found.png';
import wrlog from '../../helper/functions/wrlog';
import DepartmentUnit from '../../communicator/http/Departments';

const Departments = (props) => {

    // this.successNotification.
    const departmentUnit = new DepartmentUnit();

    const successNotification = useRef();
    const errorNotification = useRef();

    const [loading, setLoading] = useState(true);
    const [departments, setDepartments] = useState([]);

    const [department, setDepartment] = useState({});
    const [newDepartment, setNewDepartment] = useState({});
    const [newDepartmentShow, setNewDepartmentShow] = useState(true);

    useEffect(() => {
        loadDepartments();
    }, []);

    const loadDepartments = () => {
        departmentUnit.listDepartments((_departments) => {
            setLoading(false);
            setDepartments(_departments);
        }, () => {
            setLoading(false);
        })
    }

    const save = () => {
        setLoading(true);

        if(department.id === null){
            create();
        }else{
            update();
        }
    }

    const create = () => {
        departmentUnit.createDepartment({ title: department.title }, (res) => {
            let cloned = cloneArray(department);
            cloned.id = res.id;
            setDepartment(cloned);
    
            loadDepartments();
        }, (err) => { 
            setLoading(false);
         })
    }

    const update = () => {
        departmentUnit.updateDepartment({ department_id: department.id, title: department.title }, () => {
            loadDepartments();
        }, (err) => { 
            setLoading(false);
         })
    }
    
    const setDepartmentTitle = (title) => {
        let cloned = cloneArray(department);
        cloned.title = title;
        setDepartment(cloned);
    }

    return (
        <div className='innerContainerWrapper'>
            <div className='secondSidebar'>
                <Table className="table_container">
                    <TableHead style={{ position: 'sticky', top: 0 }}>
                        <TableRow>
                            <TableCell>{translate('departments')}</TableCell>
                            <TableCell>
                                <div className="button" style={{
                                    width: 40,
                                    height: 40,
                                    padding: 0,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    float: 'right'
                                }} onClick={() => {
                                    setDepartment(
                                        {
                                            title: '',
                                            id: null
                                        }
                                    )

                                }}>+</div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            departments.map((currentDepartment, key) => {
                                return <TableRow className={`rowToSelect ${currentDepartment.id === department.id ? "selectedRow" : ""}`} key={key} style={{ cursor: 'pointer' }} onClick={() => {

                                    if (department.id === currentDepartment.id) return false;

                                    setDepartment(prev => ({ ...currentDepartment }))

                                }}>
                                    <TableCell colSpan={2}>
                                        {currentDepartment.title}
                                    </TableCell>
                                </TableRow>
                            })
                        }

                    </TableBody>
                </Table>
            </div>
            <div className='containerWithSecondSidebar'>
                {department !== undefined && department.id !== undefined ? <> <Container fluid>
                    <Row>
                        <Col md={8}>
                            {/* <Link className="go_back_button" to="/">zurück</Link><br /><br /> */}
                            <h1>{translate('department')}</h1>
                        </Col>
                        <Col md={4} style={{ textAlign: 'right' }}>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{ marginTop: 20, marginBottom: 30 }}>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>

                            <Container fluid style={{ alignItems: 'center' }}>
                                <Row style={{ paddingBottom: 10 }}>
                                    <Col md={4}>Titel</Col>
                                    <Col md={1}></Col>
                                </Row>
                                <Row style={{ paddingTop: 10 }}>
                                    <Col md={4}>
                                        <TextField id="standard-basic" className="filled" value={department.title} label="Titel" onChange={value => setDepartmentTitle(value.target.value)} /><br />
                                    </Col>
                                    <Col md={1}>
                                        <div class="button iconButton" onClick={() => {
                                            save()
                                        }}><img src={saveButton} /></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>

                        </Col>
                    </Row>
                </Container>
                </> : <>
                    <Container>
                        <Row>
                            <Col style={{ paddingTop: 100, textAlign: 'center', alignItems: 'center' }}>
                                <img src={NotFound} style={{ width: 400 }} /><br />
                                <h4 style={{ maxWidth: 700, display: 'inline-block' }}>{translate('please_choose_a_department')}</h4>
                            </Col>
                        </Row>
                    </Container>
                </>}

            </div>
            <Notification ref={successNotification} text="Added User successfully" type="success" />
            <Notification ref={errorNotification} text="Something went wrong. Please try again." type="error" />
            <Loading visible={loading} />
        </div >

    )

}

export default Departments;