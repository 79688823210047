import UserUnit from '../../communicator/http/User';

export const isLoggedIn = () => {

    const userUnit = new UserUnit();

    return new Promise((resolve, reject) => {

            userUnit.validateToken(() => resolve(), () => reject())
            
    });

}

export const getUserToken = () => localStorage.getItem('token');



// Check if user has certain permission
export const has_permission = (permission_type, action) => {

    let permissions = sessionStorage.getItem('permissions');

    if (permissions == null) return false;

    permissions = JSON.parse(permissions).data.permissions;

    if (permissions[permission_type] === undefined) return false;

    return permissions[permission_type][action] === true;

}