import { getUserToken } from '../../helper/functions/auth';
import Unit from './index';


export default class DepartmentUnit extends Unit {

    constructor() {
        super();
    }

    listDepartments = (cbSuccess, cbError) => {
        this.get('/departments', cbSuccess, cbError);
    }

    createDepartment = (body, cbSuccess, cbError) => {
        this.post('/departments', body, cbSuccess, cbError);
    }

    updateDepartment = (body, cbSuccess, cbError) => {
        this.put('/departments', body, cbSuccess, cbError);
    }
    

}



