import { getUserToken } from '../../helper/functions/auth';
import Unit from './index';


export default class UserUnit extends Unit {

    constructor() {
        super();
    }

    login = (email, password, cbSuccess, cbError) => {

        let body = {
            email: email,
            password: password,
            is_admin: true,
        }

        this.post('/signin', body, cbSuccess, cbError);
    }

    validateToken = (cbSuccess, cbError) => {

        let body = {
            is_admin: true,
        }

        this.post('/validatetoken', body, cbSuccess, cbError);

    }

    getPermissions = (cbSuccess, cbError) => {
        this.get('/permission', cbSuccess, cbError);
    }

    create = (body, cbSuccess, cbError) => {

        this.post('/users', body, cbSuccess, cbError);

    }

    getUserData = (userId, cbSuccess, cbError) => {

        if (userId === null) {
            this.get(`/users`, cbSuccess, cbError);
        } else {
            this.get(`/users/${userId}`, cbSuccess, cbError);
        }

    }

    update = (body, cbSuccess, cbError) => {

        body.token = getUserToken();

        this.put('/users', body, cbSuccess, cbError);

    }

    list = (cbSuccess, cbError) => {

        let body = {
            token: getUserToken()
        }
        this.call('list_users', body, cbSuccess, cbError, 'admin_users_list');

    }

    deleteUser = (userId, cbSuccess, cbError) => {
        this.delete(`/users`, { id: userId }, cbSuccess, cbError);
    }

    forgotPassword = (email, cbSuccess, cbError) => {
        this.post(`/lostpassword`, { email: email }, cbSuccess, cbError);
    }

    resetPassword = (password, key, secret, cbSuccess, cbError) => {
        this.post(`/resetpassword`, {
            password: password,
            key: key,
            secret: secret
        }, cbSuccess, cbError);
    }

}



