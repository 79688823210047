import { Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CompanyUnit from '../../communicator/http/Company';
import UserUnit from '../../communicator/http/User';
import ToolTip from '../../elements/tooltip';
import Loading from '../../helper/screens/Loading';
import { translate } from '../../helper/multilingual';
import TopBar from '../../elements/topbar';


const Dashboard = () => {

    const [loading, setLoading] = useState(false);
    const [filterActive, setFilterActive] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchNameTerm, setSearchNameTerm] = useState('');
    const [sortStatus, setSortStatus] = useState(null);
    const [name, setName] = useState('');


    const [stats, setStats] = useState({
        valid: null,
        invalid: null,
        inReview: null,
    })

    const [page, setPage] = useState(1);

    const perPage = 25;

    const companyUnit = new CompanyUnit();
    const userUnit = new UserUnit();

    useEffect(() => {
        userUnit.getUserData(null, (res) => {
            setName(res.firstName + ' ' + res.lastName)
        }, () => { });
    }, []);

    useEffect(() => {

        if (page > 0) {
            loadUsers();
        }

    }, [page]);

    useEffect(() => {

        setPage(0);
        setTimeout(() => {
            setPage(1);
        }, 100)

    }, [sortStatus]);

    const doSearch = (e) => {
        e.preventDefault();
        setFilterActive(true);

        if (searchNameTerm !== "") {
            loadUsers();
        }
    }

    const loadUsers = () => {

        setLoading(true);

        let queryArgs = {
            page: page,
            per_page: perPage,
            search_term: searchNameTerm,
            status: sortStatus
        };

        companyUnit.listUsers(queryArgs, (res) => {

            setLoading(false);

            setSelectedUsers(res.users);

            let valid = 0;
            let invalid = 0;
            let inReview = 0;

            res.users.forEach(user => {
                if (user.status === 'valid') {
                    valid++;
                } else if (user.status === 'invalid') {
                    invalid++;
                }
                if (user.hasCertificatesInReview) {
                    inReview++;
                }
            });

            setStats({
                valid: res.statistics.valid,
                invalid: res.statistics.invalid,
                inReview: res.statistics.in_review,
                total: res.statistics.total
            })

        }, (err) => {
            setLoading(false);
        })
    }


    const renderPagination = () => {

        let out = [];

        for (let i = 1; i <= Math.ceil(stats.total / perPage); i++) {
            out.push(
                <div
                    className={`paginationButton ${i === page ? 'current' : undefined}`}
                    onClick={() => setPage(i)}
                >
                    {i}
                </div>
            );
        }

        return <div className='paginationContainer'>
            {out}
        </div>

    }

    const translateStatus = (status) => {
        switch (status) {
            case 'valid':
                return "gültig";
            case 'invalid':
                return "ungültig";
            case 'in_review':
                return "zu überprüfen";
        }
    }

    return <>
        <Container fluid>
            <TopBar name={name} logout={() => {
                localStorage.removeItem('token');
                localStorage.removeItem('isAdmin');
                window.location.reload();
            }} />
            <Row style={{ marginTop: 44 }}>
                <Col md={8}>

                </Col>
                <Col md={4} style={{ textAlign: 'right' }}>

                </Col>
            </Row>
            <form onSubmit={doSearch}>
                <Row style={{ marginTop: 44 }}>
                    <Col md={6}>
                        <TextField id="standard-basic" className="filled" value={searchNameTerm} label="Personen durchsuchen" onChange={value => setSearchNameTerm(value.target.value)} /><br />
                    </Col>
                    <Col md={6}>
                        <div
                            onClick={doSearch}
                            style={{ paddingTop: 17, paddingBottom: 18 }}
                            class={`button ${searchNameTerm == '' ? 'disabled' : ''}`}>
                            suchen
                        </div>
                    </Col>
                </Row>
            </form>
            <Row style={{ marginTop: 44 }}>
                <Col md={3}>
                    <div
                        className="card"
                        onClick={() => {
                            setFilterActive(true);
                            setSortStatus("valid");
                        }}
                    >
                        <div className="cardHeadline green">{stats.valid}</div>
                        <div className="cardLabel">Gültig</div>
                    </div>
                </Col>
                <Col md={3}>
                    <div
                        className="card"
                        onClick={() => {
                            setFilterActive(true);
                            setSortStatus("invalid");
                        }}
                    >
                        <div className="cardHeadline red">{stats.invalid}</div>
                        <div className="cardLabel">Ungültig</div>
                    </div>
                </Col>
                <Col md={3}>
                    <div
                        className="card"
                        onClick={() => {
                            setFilterActive(true);
                            setSortStatus("inReview");
                        }}
                    >
                        <div className="cardHeadline yellow">{stats.inReview}</div>
                        <div className="cardLabel">Zu überprüfen</div>
                    </div>
                </Col>
                <Col md={3} style={{ alignItems: 'center' }}>
                    {filterActive && <>
                        <div
                            className="card"
                            style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                maxWidth: 142,
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                setSortStatus(null);
                                setFilterActive(false);
                                setSearchNameTerm('');

                                setPage(0);
                                setTimeout(() => {
                                    setPage(1);
                                }, 100)
                            }}
                        >
                            <div className="cardLabel red">Filter zurücksetzen</div>
                        </div>
                    </>
                    }
                </Col>
            </Row>
        </Container>
        <Container fluid>
            <Row style={{ marginTop: 44, textAlign: 'right' }}>
                <Col md={12}>
                    <Link to={'/user/new'} className={'button'}>{translate('add_new_user')}</Link>
                </Col>
            </Row>
            <Row style={{ marginTop: 0 }}>
                <Col md={12}>
                    <Table className="table_container" style={{ marginTop: 20 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nachname</TableCell>
                                <TableCell>Vorname</TableCell>
                                <TableCell>E-Mail</TableCell>
                                <TableCell>Abteilung</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                selectedUsers.map((user, key) => {

                                    let tooltipItems = [];


                                    if (sessionStorage.getItem('isAdmin') === "true") {
                                        tooltipItems.push({
                                            label: "Berechtigungen",
                                            to: "/user/" + user.id,
                                        })
                                    }

                                    if (user.hasCertificatesInReview) {
                                        tooltipItems.push({
                                            label: "Zertifikat überprüfen",
                                            to: "check/" + user.id
                                        });
                                    }

                                    return <TableRow key={key}>
                                        <TableCell>
                                            {user.lastName}
                                        </TableCell>
                                        <TableCell>
                                            {user.firstName}
                                        </TableCell>
                                        <TableCell>
                                            <a href={`mailto:${user.email}`}>{user.email}</a>
                                        </TableCell>
                                        <TableCell>
                                            {user.department}
                                        </TableCell>
                                        <TableCell>
                                            {user.isAdmin}
                                        </TableCell>
                                        <TableCell>
                                            {tooltipItems.length > 0 && <ToolTip items={tooltipItems} />}
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'right' }}>

                                            {
                                                user.hasCertificatesInReview && <div style={{ marginRight: 10 }} className={`statusBadge in_review`}>{translateStatus('in_review')}</div>
                                            }

                                            <div className={`statusBadge ${user.status}`}>{translateStatus(user.status)}</div>
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </Col>
            </Row>
        </Container>
        <Container className="" fluid>
            <Row style={{ marginTop: 20 }}>
                <Col md={12}>
                    {renderPagination()}
                </Col>
            </Row>
        </Container>


        <Container className="footer" fluid>
            <Row style={{ marginTop: 44 }}>
                <Col md={12}>
                    <a target="_blank" href="https://3garbeitsplatz.com/datenschutz">Datenschutz</a>
                    <a target="_blank" href="https://3garbeitsplatz.com/impressum">Impressum</a>
                </Col>
            </Row>
        </Container>
        <Loading visible={loading} />
    </>

}

export default Dashboard;