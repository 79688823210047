import { getUserToken } from '../../helper/functions/auth';
import Unit from './index';


export default class CheckUnit extends Unit {

    constructor() {
        super();
    }

    listCertificates = (cbSuccess, cbError) => {
        this.get('/check', cbSuccess, cbError);
    }

    acceptCertificate = (certificateId, cbSuccess, cbError) => {
        let body = {
            id: certificateId,
            accepted: true
        };
        this.post('/check', body, cbSuccess, cbError);
    }

    denyCertificate = (certificateId, cbSuccess, cbError) => {
        let body = {
            id: certificateId,
            accepted: false
        };
        this.post('/check', body, cbSuccess, cbError);
    }

}



