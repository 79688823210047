import { getUserToken } from '../../helper/functions/auth';
import Unit from './index';


export default class ValidityUnit extends Unit {

    constructor() {
        super();
    }

    list = (cbSuccess, cbError) => {
        this.get('/validity', cbSuccess, cbError);
    }

    create = (body, cbSuccess, cbError) => {
        this.post('/validity', body, cbSuccess, cbError);
    }

    changeRules = (body, cbSuccess, cbError) => {
        this.put('/validity', body, cbSuccess, cbError);
    }

    remove = (rulesetId, cbSuccess, cbError) => {
        let body = {
            id: rulesetId
        };
        this.delete('/validity', body, cbSuccess, cbError);
    }

}



